import { render, staticRenderFns } from "./ProdMenu.vue?vue&type=template&id=1ba874ea&scoped=true&"
import script from "./ProdMenu.vue?vue&type=script&lang=js&"
export * from "./ProdMenu.vue?vue&type=script&lang=js&"
import style0 from "./ProdMenu.vue?vue&type=style&index=0&id=1ba874ea&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ba874ea",
  null
  
)

export default component.exports
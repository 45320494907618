<template>
  <div>
    <el-divider></el-divider>
    <el-menu :default-active="activeIndex" @select="goToPage">
      <el-menu-item
        v-for="item in menuList"
        :key="item.index"
        :index="item.index"
      >
        <span class="nav-item"></span>{{ item.label }}
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        { index: "1", label: "设计理念", routerName: "designConcept" },
        { index: "2", label: "特性", routerName: "character" },
        { index: "3", label: "应用场景", routerName: "applicationScene" },
        { index: "4", label: "产品对比", routerName: "comparison" },
        { index: "5", label: "架构", routerName: "architecture" },
        { index: "6", label: "案例", routerName: "case" },
      ],
    };
  },
  props: {
    activeIndex: {
      type: String,
      required: true,
    },
  },
  methods: {
    goToPage(index) {
      const i = this.menuList.findIndex((item) => item.index == index);
      if (i > -1) {
        this.$router.push({ name: this.menuList[i].routerName });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.el-menu {
  border: none;
  .el-menu-item {
    &.is-active {
      color: @themeRed;
      .nav-item {
        padding: 0 10px;
        border-left: 3px solid @themeRed;
        font-weight: 700;
      }
    }
    &:focus,
    &:hover {
      background-color: #fff;
    }
  }
}
</style>
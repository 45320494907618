<template>
  <div class="main container product-page">
    <el-backtop :bottom="180"></el-backtop>
    <Breadcrumb :list="breadcrumbList" />
    <div class="content">
      <div class="left">
        <h2 class="title">产品对比</h2>
        <div class="summary animate__animated animate__fadeIn">
          <p>
            Redis
            以其高性能常被用于构建缓存系统，以解决传统数据库吞吐量不足的问题。
            随着行业应用对基础软件需求的深化和细化，Redis
            缓存系统的一些固有的缺点更多地暴露出来。
          </p>
          <p>
            ShuangzhaoDB
            旨在提供更加灵活、安全、正确和易管理的增强缓存解决方案， 弥补 Redis
            缓存系统的不足。 并兼具 Redis 的优点，比如兼容 Redis 数据类型，
            提供集群高可用、数据生命周期管理等。
          </p>
          <el-image
            :src="require('@/assets/images/advantage.png')"
            fit="cover"
            :preview-src-list="[require('@/assets/images/advantage.png')]"
          />
        </div>
      </div>
      <ProdMenu class="right hidden-sm-and-down" activeIndex="4" />
    </div>
    <div class="advantage animate__animated animate__fadeIn">
      <section>
        <h3 class="sub-title">高性能</h3>
        <p>
          实际的应用场景中，数据访问方式、网络交互次数、代码运行方式、缓存命中率等都会影响应用的整体性能。ShuangzhaoDB
          性能更优。模拟信用卡消费积分的业务场景，分别使用 ShuangzhaoDB 和 Redis
          做为缓存产品，测试两者的性能差异，测试结果：
        </p>
        <table class="test-result">
          <thead>
            <tr>
              <th></th>
              <th>ShuangzhaoDB</th>
              <th>Redis</th>
              <th>备注</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>延时</td>
              <td>3ms</td>
              <td>18ms</td>
              <td rowspan="2">测试结果均为端到端的性能指标。</td>
            </tr>
            <tr>
              <td>吞吐量</td>
              <td>8247</td>
              <td>2415</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section v-for="item in advantageList" :key="item.id">
        <h3 class="sub-title">{{ item.title }}</h3>
        <ul v-if="item.contentList" class="li-container">
          <li v-for="row in item.contentList" :key="row.id">
            <span class="point">▲</span>
            <span style="color: #767676">{{ row.content }}</span>
          </li>
        </ul>
        <p v-else>{{ item.content }}</p>
      </section>
      <table class="comparison-table">
        <thead>
          <tr>
            <th>一级指标</th>
            <th>二级指标</th>
            <th>ShuangzhaoDB</th>
            <th>Redis</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="4">关键指标</td>
            <td>TPS</td>
            <td>单节点读写10W+并随节点数量线性增长</td>
            <td>单节点读写10W+</td>
          </tr>
          <tr>
            <td>储存容量</td>
            <td>随节点数量增加,实际案例中可达到TB级别</td>
            <td>未知</td>
          </tr>
          <tr>
            <td>索引</td>
            <td>支持一级、二级索引，复合索引。红黑树实现</td>
            <td>仅键值索引，hash实现</td>
          </tr>
          <tr>
            <td class="adjust-white-space">复杂SQL能力</td>
            <td>支持标准SQL92和部分SQL99</td>
            <td>不支持</td>
          </tr>
          <tr>
            <td>集成要求</td>
            <td></td>
            <td>
              <p>1、对设备配置要求不敏感，配置越高处理性能越好</p>
              <p>2、内置集成ZK</p>
              <p>3、可以安装于物理机、虚拟机、容器，支持云化部署</p>
              <p>4、提供对K8S operator的支持</p>
            </td>
            <td>支持物理机、虚拟机、容器化和云化部署</td>
          </tr>
          <tr>
            <td rowspan="2">高可用</td>
            <td>容灾</td>
            <td>
              <p>原生支持主备、双活、一主多备、多活容灾</p>
              <p>原生支持本地机房冷热灾备和跨机房冷热灾备</p>
            </td>
            <td>分布式单集群</td>
          </tr>
          <tr>
            <td>副本数</td>
            <td>
              支持多副本。一套数据副本宕机后，应用无影响。若数据为3副本，当同一数据3副本节点都宕机后，集群不可用。
            </td>
            <td>
              支持多个slaver，但如果集群中超过半数以上节点的master宕机，无法仲裁导致集群服务不可用。
            </td>
          </tr>
          <tr>
            <td>实例</td>
            <td></td>
            <td>
              <p>
                支持单台物理机器上多实例，但不建议单台物理机器上启动多实例（避免资源争用）
              </p>
              <p>单实例多线程并行处理</p>
            </td>
            <td>推荐单服务器多实例，单实例单线程</td>
          </tr>
          <tr>
            <td>开源支持</td>
            <td></td>
            <td>不支持</td>
            <td>开源支持</td>
          </tr>
          <tr>
            <td>多租户</td>
            <td></td>
            <td>使用K8S来实现多租户</td>
            <td>没有K8S operator的支持</td>
          </tr>
          <tr>
            <td>多用户</td>
            <td></td>
            <td>
              支持，但无法配置独立表空间，支持用户、角色权限管理，暨：不同表使用到的内存资源做不到隔离，所有的表都可以用到所有的内存空间，但是每个表可以设置配额管理，最大不允许超过多少条记录
            </td>
            <td>不支持</td>
          </tr>
          <tr>
            <td>scheme</td>
            <td></td>
            <td>
              分区表及复制表模式并存，分布键是指为分区表选择的自动分区的字段，ShuangzhaoDB自动根据分布键的数据，哈希后将数据放到集群不同的分区
            </td>
            <td>集群模式仅可用分区表，主从模式仅可用复制表。</td>
          </tr>
          <tr>
            <td rowspan="4">ACID</td>
            <td>原子性</td>
            <td>支持</td>
            <td>不支持</td>
          </tr>
          <tr>
            <td>一致性</td>
            <td>强一致性</td>
            <td>不支持，主副本数据不保证一致性。</td>
          </tr>
          <tr>
            <td>隔离性</td>
            <td>支持</td>
            <td>支持</td>
          </tr>
          <tr>
            <td>持久性</td>
            <td>
              <p>支持以Snapshot方式持久化</p>
              <p>
                支持事务级别的持久化，Snapshot与Commandlog结合，数据加载迅速
              </p>
            </td>
            <td>
              支持，通过RDB或AOF方式，RDB无法做到事务级别持久化，AOF方式数据加载缓慢
            </td>
          </tr>
          <tr>
            <td>SQL标准化</td>
            <td></td>
            <td>标准SQL都支持，但与ORACLE语法存在差异性，主要表现在函数上。</td>
            <td>不支持</td>
          </tr>
          <tr>
            <td>存储过程</td>
            <td></td>
            <td>支持存储过程(JAVA、SQL)，可实现复杂运算</td>
            <td>不支持，复杂运行由业务代码实现</td>
          </tr>
          <tr>
            <td>自定义函数</td>
            <td></td>
            <td>支持</td>
            <td>不支持</td>
          </tr>
          <tr>
            <td>视图</td>
            <td></td>
            <td>支持</td>
            <td>不支持</td>
          </tr>
          <tr>
            <td>索引</td>
            <td></td>
            <td>支持</td>
            <td>使用哈希表保存kv数据，rehash时性能下降</td>
          </tr>
          <tr>
            <td>连接数</td>
            <td></td>
            <td>理论无限制</td>
            <td>未知</td>
          </tr>
          <tr>
            <td>存储方式</td>
            <td></td>
            <td>字段个数最多1024个，行存储。支持直接二进制和JSON存储</td>
            <td>不支持行存储。</td>
          </tr>
          <tr>
            <td>执行计划</td>
            <td></td>
            <td>支持</td>
            <td>无</td>
          </tr>
          <tr>
            <td>安全</td>
            <td>用户名密码</td>
            <td>写在配置文件中，密码支持密文存储。</td>
            <td>支持在配置文件中配置，不支持密码密文存储。</td>
          </tr>
          <tr>
            <td rowspan="2">运维</td>
            <td>管理工具</td>
            <td>
              <p>自带命令行管理工具SQLCMD</p>
              <p>自带图形管理工具VMC</p>
              <p>
                支持跟流行的三方监控工具集成，如：普罗米修斯、grafana、New
                relic等
              </p>
            </td>
            <td>提供命令行界面、图形界面</td>
          </tr>
          <tr>
            <td>集群扩容</td>
            <td>自动化在线扩容、缩容，一键操作</td>
            <td>支持，多个步骤，实例启动->加入集群->迁移数据</td>
          </tr>
          <tr>
            <td>TTL</td>
            <td></td>
            <td>
              支持根据数量、时间的生命周期管理，可靠的 TTL 数据 过期删除机制。
            </td>
            <td>
              支持基于时间的生命周期管理，TTL
              使用随机挑选算法，存在不可靠的数据过期删除，可能造成数据堆积。
            </td>
          </tr>
          <tr>
            <td>导入导出</td>
            <td></td>
            <td>
              <p>支持通过文件批量导入，支持Kafka订阅和批量导入，支持JDBC导入</p>
              <p>
                支持导出的方式有：文件导出，导出到hadoop，通过JDBC导出到其他数据库，导出到Kafka，导出到ElasticsSearch，支持Socket自定义导出。
              </p>
              <p>支持基于事件的数据导出</p>
            </td>
            <td>
              <p>支持指定的 key 在 redis 之间的导入和导出或迁移。</p>
              <p>不支持导入外部系统或者文件。</p>
            </td>
          </tr>
          <tr>
            <td>定时任务</td>
            <td></td>
            <td>支持定时任务执行和任务编排，支持定时跑批</td>
            <td>不支持</td>
          </tr>
          <tr>
            <td>编程接口</td>
            <td></td>
            <td>
              <p>
                支持所有流行的编程语言（如：JAVA、C/C++、C#、Python、Go、PHP、Node.js、Erlang等）
              </p>
              <p>支持 REST，JDBC 等规范，支持 Redis 客户端接入协议。</p>
            </td>
            <td>支持所有流行的编程语言</td>
          </tr>
          <tr>
            <td class="adjust-white-space">数据自动分布</td>
            <td></td>
            <td>
              可在数据写入、数据复制、集群扩缩容等多种场景下，自动完成数据均衡分布操作。
            </td>
            <td>集群扩缩容时需要手工重新分布数据</td>
          </tr>
          <tr>
            <td>信创满足</td>
            <td></td>
            <td>完全国产自主产品，已通过信创兼容测试</td>
            <td>未知</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import ProdMenu from "@/components/ProdMenu";
import "animate.css";
export default {
  name: "Comparison",
  components: {
    Breadcrumb,
    ProdMenu,
  },
  data() {
    return {
      breadcrumbList: [
        { id: 1, path: "/", label: "ShuangzhaoDB" },
        { id: 2, path: "/product", label: "产品" },
        { id: 3, path: "", label: "产品对比" },
      ],
      advantageList: [
        {
          id: 1,
          title: "高缓存命中率",
          content:
            "ShangzhaoDB 可管理关系型缓存，客户端通过原生 API 或 JDBC 操作数据。关系型存储适合保存业务明细，使用 SQL 进行灵活、复杂的查询。避免 Redis 缓存数据冗余和命中率低下问题。",
        },
        {
          id: 2,
          title: "数据安全正确",
          content:
            "毫不妥协的 ACID 特性，保证了数据的正确性，不会出现如脏读、幻读等问题。严格保证数据持久性，在任何情况下都不丢失数据。适合对缓存数据的安全和正确性有更高要求的系统。",
        },
        {
          id: 3,
          title: "高效运维管理",
          contentList: [
            {
              id: 31,
              content:
                "ShuangzhaoDB 是原生分布式的，集群管理不依赖任何中间件形式。",
            },
            {
              id: 32,
              content: "集群节点对等，同时提供数据读写服务，高效利用资源。",
            },
            {
              id: 33,
              content: "扩缩容过程无需人工干预，性能随节点数量线性增长。",
            },
            {
              id: 34,
              content:
                "提供图形化监控和第三方监控工具插件，展示几十种性能和状态指标。",
            },
            {
              id: 35,
              content:
                "提供集群高可用性，并可通过多活部署实现异地容灾，数据自动复制。",
            },
            {
              id: 36,
              content:
                "自主可控：ShuangzhaoDB 已通过信创产品认证，提供专业售后技术团队，降低企业总拥有成本。",
            },
          ],
        },
        {
          id: 4,
          title: "编程零门槛",
          content: "采用标准 SQL 或标准 Redis RESP 进行客户端调用。",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/product.less";
.el-backtop {
  color: @themeRed;
  &:hover {
    background-color: rgba(245, 235, 230, 0.75);
  }
}
.summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  p {
    padding-bottom: 15px;
  }
}
.advantage {
  p {
    padding-bottom: 15px;
  }
  .li-container {
    padding-bottom: 15px;
    .point {
      padding-right: 16px;
    }
  }
}

table.test-result {
  margin-bottom: 20px;
  tr th:last-of-type,
  tr td[rowspan] {
    border-right: 0;
  }

  tbody tr:last-of-type th,
  tr:last-of-type td,
  tr td[rowspan] {
    border-bottom: 0;
  }
}
table.comparison-table {
  margin-top: 15px;

  tr th:last-of-type,
  tr td:last-of-type {
    border-right: 0;
  }
}
table,
tr th,
tr td {
  border: 1px solid #ccc;
}

table {
  box-sizing: border-box;
  width: 100%;
  border-radius: 6px;
  border-spacing: 0;
  text-align: left;
  color: #767676;
  thead {
    color: #555;
  }
  tr th,
  tr td {
    border-left: 0;
    border-top: 0;
    padding: 8px 15px;
    &.adjust-white-space {
      white-space: nowrap;
    }
  }
  tbody tr:last-of-type th,
  tr:last-of-type td {
    border-bottom: 0;
  }
}

@media screen and(max-width:991px) {
  .el-backtop {
    display: none;
  }
  .advantage {
    .sub-title {
      padding-bottom: 5px;
    }
  }
  table {
    table-layout: fixed;
    margin-top: 25px;
    font-size: 12px;
    thead > tr > th:nth-of-type(-n + 2) {
      width: 65px;
    }
    tr th,
    tr td {
      padding: 5px;
      &.adjust-white-space {
        white-space: normal;
      }
    }
  }
}
</style>